import AudioRecorder from "./components/AudioUploader";
import "./App.scss";
// import AudioUploader from "./components/AudioUploader";

function App() {
  return (
    <div>
      <AudioRecorder />
    </div>
  );
}

export default App;
