import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { sendAudio } from "../Redux/store/audioSlice";
import {
  Typography,
  CircularProgress,
  Alert,
  Box,
  Container,
  Paper,
  IconButton,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import MicIcon from "@mui/icons-material/Mic";
import MicOffIcon from "@mui/icons-material/MicOff";
import SendIcon from "@mui/icons-material/Send";

const AudioRecorder = () => {
  const dispatch = useDispatch();
  const { loading, error } = useSelector((state) => state.audio);

  const [isRecording, setIsRecording] = useState(false);
  const [audioURL, setAudioURL] = useState(null);
  const [chatHistory, setChatHistory] = useState([]);
  const [recordingTime, setRecordingTime] = useState(0);
  const mediaRecorderRef = useRef(null);
  const audioChunksRef = useRef([]);
  const timerRef = useRef(null);
  // const audioRef = useRef(null);

  useEffect(() => {
    if (isRecording) {
      timerRef.current = setInterval(() => {
        setRecordingTime((prevTime) => prevTime + 1);
      }, 1000);
    } else {
      clearInterval(timerRef.current);
      setRecordingTime(0);
    }
    return () => clearInterval(timerRef.current);
  }, [isRecording]);

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
  };

  const startRecording = async () => {
    const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
    mediaRecorderRef.current = new MediaRecorder(stream);

    mediaRecorderRef.current.ondataavailable = (event) => {
      audioChunksRef.current.push(event.data);
    };

    mediaRecorderRef.current.onstop = () => {
      const audioBlob = new Blob(audioChunksRef.current, { type: "audio/wav" });
      const url = URL.createObjectURL(audioBlob);
      setAudioURL(url);
      audioChunksRef.current = [];
    };

    mediaRecorderRef.current.start();
    setIsRecording(true);
  };

  const stopRecording = () => {
    mediaRecorderRef.current.stop();
    setIsRecording(false);
  };

  const handleUpload = async () => {
    if (audioURL) {
      try {
        // Fetch the audio blob from the recorded URL
        const response = await fetch(audioURL);
        const audioBlob = await response.blob();

        // Dispatch action to send the audio and get the bot's response
        const res = await dispatch(sendAudio(audioBlob));

        // Assuming the response from sendAudio is a Base64-encoded audio string
        if (res.payload) {
          setChatHistory((prev) => [
            ...prev,
            { type: "user", audioUrl: audioURL },
            { type: "bot", audioUrl: res.payload },
          ]);
          const botAudio = new Audio(res.payload);
          botAudio.play().catch((error) => {
            console.error("Failed to play bot audio response:", error);
          });
        } else {
          console.error("No audio response received from the server.");
        }
      } catch (error) {
        console.error("Failed to upload audio:", error);
      }
    }
  };

  return (
    <Container maxWidth="md">
      <Box mt={4} mb={2} textAlign="center">
        <Typography
          variant="h4"
          style={{ color: "#3f51b5", fontWeight: "bold" }}
        >
          Voicellm-POC
        </Typography>
      </Box>
      <Paper
        elevation={3}
        style={{
          padding: 16,
          maxHeight: "60vh",
          minHeight: "350px",
          overflow: "auto",
          backgroundColor: "#f0f4f8",
          borderRadius: 8,
        }}
      >
        <List>
          {chatHistory.map((entry, index) => (
            <ListItem
              key={index}
              alignItems="flex-start"
              style={{
                justifyContent:
                  entry.type === "user" ? "flex-end" : "flex-start",
              }}
              className="py-0"
            >
              <ListItemText
                primary={
                  entry.type === "user" ? (
                    <Typography
                      color="primary"
                      variant="subtitle1"
                      align="right"
                    >
                      You
                    </Typography>
                  ) : (
                    <Typography
                      style={{ color: "#ff5722" }}
                      variant="subtitle1"
                      align="left"
                    >
                      Bot
                    </Typography>
                  )
                }
                secondary={
                  entry.audioUrl ? (
                    <audio
                      controls
                      src={entry.audioUrl}
                      style={{ marginTop: 8 }}
                    />
                  ) : (
                    entry.text
                  )
                }
                style={{
                  textAlign: entry.type === "user" ? "right" : "left",
                  maxWidth: "70%", // Optional: limit message width for better readability
                }}
              />
            </ListItem>
          ))}
        </List>
      </Paper>
      <Box display="flex" alignItems="center" mt={2}>
        <Box
          flex={1}
          borderRadius={4}
          p={2}
          mr={2}
          style={{
            backgroundColor: "#e8f0fe",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography variant="body2" style={{ color: "#607d8b" }}>
            {isRecording
              ? `Recording: ${formatTime(recordingTime)}`
              : "Click the mic to start recording"}
          </Typography>
        </Box>
        <IconButton
          onClick={isRecording ? stopRecording : startRecording}
          color={isRecording ? "secondary" : "primary"}
        >
          {isRecording ? (
            <MicOffIcon fontSize="large" />
          ) : (
            <MicIcon fontSize="large" />
          )}
        </IconButton>
        <IconButton
          onClick={handleUpload}
          color="primary"
          disabled={!audioURL || loading}
          style={{ marginLeft: 8 }}
        >
          {loading ? (
            <CircularProgress size={24} />
          ) : (
            <SendIcon fontSize="large" />
          )}
        </IconButton>
      </Box>
      {error && (
        <Box my={2}>
          <Alert severity="error">{error}</Alert>
        </Box>
      )}
    </Container>
  );
};

export default AudioRecorder;
