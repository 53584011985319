import baseApi from "../baseApi";

const sendAudio = async (audioBlob) => {
  try {
    // Creating FormData to send the audio blob
    const formData = new FormData();
    formData.append("payload", audioBlob, "audio.m4a"); // Using 'payload' as the key

    // Sending the POST request with specific headers for this endpoint
    const response = await baseApi.post("/voice-assistant/", formData, {
      responseType: "arraybuffer",
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    const blob = new Blob([response.data], { type: "audio/wav" });

    // Create an audio element and play the audio
    const url = window.URL.createObjectURL(blob);

    return url;
  } catch (error) {
    throw new Error(
      error.response
        ? error.response.data.message
        : "An error occurred while sending audio"
    );
  }
};

export default {
  sendAudio,
};
