import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import audioService from '../services/audioService';

export const sendAudio = createAsyncThunk('audio/sendAudio', async (audioFile) => {
    const response = await audioService.sendAudio(audioFile);
    return response; // Adjust according to the response structure
});

const audioSlice = createSlice({
    name: 'audio',
    initialState: {
        ttsResponse: null,
        loading: false,
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(sendAudio.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(sendAudio.fulfilled, (state, action) => {
                state.loading = false;
                state.ttsResponse = action.payload;
            })
            .addCase(sendAudio.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            });
    },
});

export default audioSlice.reducer;
